body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.secondary {
  background-color: #fafafa !important;
}

.imageBackground {
  background-color: rgba(123, 148, 151, 0.25);
  border-radius: 7px;
}

.toggledImage {
  background: rgb(0, 220, 0, 0.5) !important;
  border: 1px solid rgb(0, 220, 0) !important;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
  background-color: #fafafa;
}
.inputRounded {
  border-radius: 20px !important;
  background-color: #fafafa;
}
.inputRounded input::placeholder {
  color: #7b9497;
}

.MuiSelect-outlined {
  text-align: left;
}

.MuiChip-root.Mui-disabled {
  opacity: 1 !important;
}

.MuiChip-root.Mui-disabled svg {
  display: none;
}

.MuiAlert-message {
  text-align: initial;
}
